import React from "react"
import ResponsiveTableWrapper from "../../shared/ResponsiveTableWrapper"
import Text from "../../shared/Text"
import Icon from "../../shared/Icon"
import { iconSizes } from "../../../assets/themes/default"

const LandingPageServices = () => {
  return (
    <ResponsiveTableWrapper>
      <table className="pricing">
        <thead>
          <tr>
            <th>
              <Text type="subsubtitle">Landing Page Services</Text>
            </th>
            <th>Static</th>
            <th>Dynamic with Testing</th>
            <th>Fully Optimized</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Landing page programming/build</td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
            <td>Unlimited design iterations</td>
            <td>Unlimited design iterations</td>
          </tr>
          <tr>
            <td>Conversion tracking code setup</td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
          </tr>
          <tr>
            <td>Custom conversion strategy</td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
          </tr>
          <tr>
            <td>Multivariate conversion testing iteration</td>
            <td></td>
            <td>One Test Iteration</td>
            <td>3 Test Iteration</td>
          </tr>
          <tr>
            <td>Performance test and conversion reporting</td>
            <td></td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
          </tr>
          <tr>
            <td>PPC campaign review</td>
            <td></td>
            <td></td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Investment</td>
            <td>$1,000</td>
            <td>$1,600</td>
            <td>$2,100</td>
          </tr>
        </tfoot>
      </table>
    </ResponsiveTableWrapper>
  )
}
export default LandingPageServices
