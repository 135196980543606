import React from "react"
import ResponsiveTableWrapper from "../../shared/ResponsiveTableWrapper"
import Text from "../../shared/Text"
import Icon from "../../shared/Icon"
import { iconSizes } from "../../../assets/themes/default"

const FCOServices = () => {
  return (
    <ResponsiveTableWrapper>
      <table className="pricing">
        <thead>
          <tr>
            <th>
              <Text type="subsubtitle">
                Full Campaign Optimization Services
              </Text>
            </th>
            <th>Basic/Starter</th>
            <th>Intermediate</th>
            <th>
              Next Level
              <Icon name="up-arrow" size={iconSizes.small} />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Testing</td>
            <td>Up to 1 every 2 months</td>
            <td>1 test/month</td>
            <td>Up to 3 tests/month</td>
          </tr>
          <tr>
            <td>Heatmap and click-stream analysis</td>
            <td>Up to 10 pages for heatmap & click-stream analysis</td>
            <td>Up to 15 pages for heatmap & click-stream analysis</td>
            <td>Up to 25 pages for heatmap and click-stream analysis</td>
          </tr>
          <tr>
            <td>Reporting</td>
            <td>Every other month</td>
            <td>Monthly</td>
            <td>Monthly</td>
          </tr>
          <tr>
            <td>Google Ads conversion audit</td>
            <td></td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
          </tr>
          <tr>
            <td>Website personalization testing</td>
            <td></td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
          </tr>
          <tr>
            <td>Shopping cart abandonment testing</td>
            <td></td>
            <td></td>
            <td>
              <Icon name="check" size={iconSizes.small} />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Monthly Investment</td>
            <td>$600</td>
            <td>$1,200</td>
            <td>$2,400</td>
          </tr>
        </tfoot>
      </table>
    </ResponsiveTableWrapper>
  )
}
export default FCOServices
