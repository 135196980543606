import React from "react"
import ResponsiveTableWrapper from "../../shared/ResponsiveTableWrapper"
import Text from "../../shared/Text"
import Icon from "../../shared/Icon"
import { iconSizes } from "../../../assets/themes/default"

const Social = () => {
  return (
    <>
      <ResponsiveTableWrapper>
        <table className="pricing">
          <thead>
            <tr>
              <th>
                <Text type="subsubtitle">Social Media Campaigns</Text>
              </th>
              <th>Basic/Starter</th>
              <th>Intermediate</th>
              <th>
                Next Level
                <Icon name="up-arrow" size={iconSizes.small} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Social Networks - Facebook, Twitter, and/or Instagram</td>
              <td>Choice of one</td>
              <td>Choice of two</td>
              <td>Choice of three</td>
            </tr>
            <tr>
              <td>Unique Ads</td>
              <td>Up to 4</td>
              <td>Up to 6</td>
              <td>Up to 8</td>
            </tr>
            <tr>
              <td>Targeted Audiences (Keywords/Demographics)</td>
              <td>Up to 2</td>
              <td>Up to 3</td>
              <td>Up to 4</td>
            </tr>
            <tr>
              <td>Full Campaign Setup & Strategy Session</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Full Campaign Optimization</td>
              <td>
                <a href="/sign-up">Get a Quote</a>
              </td>
              <td>
                <a href="/sign-up">Get a Quote</a>
              </td>
              <td>
                <a href="/sign-up">Get a Quote</a>
              </td>
            </tr>
            <tr>
              <td>Access to Reporting/Analytics</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>A/B Testing</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Remarketing/Retargeting</td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Monthly Ad Budget</td>
              <td>$500 - $5,000</td>
              <td>$2,000 - $5,000</td>
              <td>$5,000 +</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Monthly investment</td>
              <td>$450 or 15% of ad spend (whichever is higher)</td>
              <td>$650 or 15% of ad spend (whichever is higher)</td>
              <td>$750 or 15% of ad spend (whichever is higher)</td>
            </tr>
          </tfoot>
        </table>
      </ResponsiveTableWrapper>

      <ResponsiveTableWrapper>
        <table className="pricing">
          <thead>
            <tr>
              <th>
                <Text type="subsubtitle">Social Media Design</Text>
              </th>
              <th>Basic/Starter</th>
              <th>Intermediate</th>
              <th>
                Next Level
                <Icon name="up-arrow" size={iconSizes.small} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Facebook - page content additions/landing page</td>
              <td>Choice of one</td>
              <td>Choice of two</td>
              <td>Choice of three</td>
            </tr>
            <tr>
              <td>Facebook - "Like" optimization</td>
              <td>Up to 4</td>
              <td>Up to 6</td>
              <td>Up to 8</td>
            </tr>
            <tr>
              <td>Facebook Posts written</td>
              <td>Up to 2</td>
              <td>Up to 3</td>
              <td>Up to 4</td>
            </tr>
            <tr>
              <td>Custom Twitter Background</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Tweets composed/written - 1st 10</td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Twitter Followers (25+)</td>
              <td></td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Instagram - creation</td>
              <td></td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Instagram Posts created (First 5)</td>
              <td></td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Monthly investment</td>
              <td>$750</td>
              <td>$1,100</td>
              <td>$1,500</td>
            </tr>
          </tfoot>
        </table>
      </ResponsiveTableWrapper>

      <ResponsiveTableWrapper>
        <table className="pricing">
          <thead>
            <tr>
              <th>
                <Text type="subsubtitle">PPC/Search Campaigns</Text>
              </th>
              <th>Basic/Starter</th>
              <th>Intermediate</th>
              <th>
                Next Level
                <Icon name="up-arrow" size={iconSizes.small} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Monthly Spend/Search Budget</td>
              <td>Monthly Spend up to $2500</td>
              <td>Monthly Spend $2501 to $7500</td>
              <td>Monthly Spend $7501 to $20,000</td>
            </tr>
            <tr>
              <td>Search Engine</td>
              <td>Choice of Google or Bing</td>
              <td>Google & Bing</td>
              <td>Google & Bing</td>
            </tr>
            <tr>
              <td>Ongoing keyword development and tweaking</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Dynamic keyword insertion into ads</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Google analytics integration & goal tracking</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Results analysis/reporting</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Strategic bid management</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Full Campaign Setup & Strategy Session</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Full Campaign Optimization</td>
              <td>
                <a href="/sign-up">Get a Quote</a>
              </td>
              <td>
                <a href="/sign-up">Get a Quote</a>
              </td>
              <td>
                <a href="/sign-up">Get a Quote</a>
              </td>
            </tr>
            <tr>
              <td>Setup and management of rule based bidding</td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>One Time Setup</td>
              <td>$750</td>
              <td>$1,000</td>
              <td>$1,000</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Monthly investment</td>
              <td>$500 or 15% ad spend (whichever is higher)</td>
              <td>$800 or 15% ad spend (whichever is higher)</td>
              <td>$1,200 or 15% ad spend (whichever is higher)</td>
            </tr>
          </tfoot>
        </table>
      </ResponsiveTableWrapper>
    </>
  )
}
export default Social
