import React from "react"
import ResponsiveTableWrapper from "../../shared/ResponsiveTableWrapper"
import Text from "../../shared/Text"
import Icon from "../../shared/Icon"
import { iconSizes } from "../../../assets/themes/default"

const EmailMarketing = () => {
  return (
    <>
      <ResponsiveTableWrapper>
        <table className="pricing">
          <thead>
            <tr>
              <th>
                <Text type="subsubtitle">Email Marketing Design</Text>
              </th>
              <th>Basic/Starter</th>
              <th>Intermediate</th>
              <th>
                Next Level
                <Icon name="up-arrow" size={iconSizes.small} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Email Creation</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Creative Built into HTML</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Edits to E-mail Creative</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Strategy Call</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Testing on E-mail Clients</td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Testing on mobile devices</td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Responsive Design/Testing</td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Copywriting</td>
              <td></td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Design of E-mails</td>
              <td>1 per month</td>
              <td>Up to 3 per month</td>
              <td></td>
            </tr>
            <tr>
              <td>Coding for E-mail</td>
              <td>1 per month</td>
              <td>Up to 3 per month</td>
              <td></td>
            </tr>
            <tr>
              <td>Custom Coding inside e-mail</td>
              <td>1 per month</td>
              <td>Up to 3 per month</td>
              <td></td>
            </tr>
            <tr>
              <td>Copywriting for Emails</td>
              <td>1 per month</td>
              <td>Up to 3 per month</td>
              <td></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Investment</td>
              <td>$600</td>
              <td>$1,000</td>
              <td>$1,400</td>
            </tr>
          </tfoot>
        </table>
      </ResponsiveTableWrapper>
      <ResponsiveTableWrapper>
        <table className="pricing">
          <thead>
            <tr>
              <th>
                <Text type="subsubtitle">
                  Email Marketing Campaign Services
                </Text>
              </th>
              <th>Basic/Starter</th>
              <th>Intermediate</th>
              <th>
                Next Level
                <Icon name="up-arrow" size={iconSizes.small} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Dedicated Account Rep</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Strategy Call</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>E-mail Metrics Reporting</td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Split A/B Landing Page Testing</td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Split A/B Subject Line Testing</td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Split A/B Sender Name Testing</td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Time and Day Testing</td>
              <td></td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
              <td>
                <Icon name="check" size={iconSizes.small} />
              </td>
            </tr>
            <tr>
              <td>Sends per Month</td>
              <td>Up to total 3 sends</td>
              <td>Up to total 6 sends</td>
              <td>Unlimited sends</td>
            </tr>
            <tr>
              <td>Full Campaign Optimization</td>
              <td>
                <a href="tel:7047505604">Call for Quote</a>
              </td>
              <td>
                <a href="tel:7047505604">Call for Quote</a>
              </td>
              <td>
                <a href="tel:7047505604">Call for Quote</a>
              </td>
            </tr>
            <tr>
              <td>E-mail send fees - using client generated List</td>
              <td>
                <a href="tel:7047505604">Call for Quote</a>
              </td>
              <td>
                <a href="tel:7047505604">Call for Quote</a>
              </td>
              <td>
                <a href="tel:7047505604">Call for Quote</a>
              </td>
            </tr>
            <tr>
              <td>E-mail send fees - using Meteor List</td>
              <td>
                <a href="tel:7047505604">Call for Quote</a>
              </td>
              <td>
                <a href="tel:7047505604">Call for Quote</a>
              </td>
              <td>
                <a href="tel:7047505604">Call for Quote</a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Monthly Investment</td>
              <td>$300</td>
              <td>$400</td>
              <td>$500</td>
            </tr>
          </tfoot>
        </table>
      </ResponsiveTableWrapper>
    </>
  )
}
export default EmailMarketing
