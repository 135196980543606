import React from "react"
import styled from "@emotion/styled"

const ResponsiveTableWrapper = ({ children }) => {
  const styleTableWrapper = {
    overflowX: `auto`,
  }

  const TableWrapper = styled.div`
    ${styleTableWrapper}
  `

  return <TableWrapper>{children}</TableWrapper>
}

export default ResponsiveTableWrapper
