import React, { useState } from "react"
import EmailMarketing from "../components/pricing/EmailMarketing"
import FCOServices from "../components/pricing/FCOServices"
import LandingPageServices from "../components/pricing/LandingPageServices"
import Layout from "../components/layout"
import Section from "../components/shared/Section"
import SEO from "../components/shared/SEO"
import Social from "../components/pricing/Social"
import StyledLink from "../components/shared/StyledLink"

import "../components/pricing/style.css"

const PricingPage = ({ location }) => {
  const [sectionToShow, setSectionToShow] = useState(
    location.state?.section || "Social"
  )

  const handleCheck = (e) => {
    setSectionToShow(e.target.getAttribute("data-target"))
  }

  return (
    <>
      <Layout>
        <SEO
          title="Pricing"
          description="A Boutique Digital Marketing Agency"
          keywords={[
            `marketing`,
            `digital`,
            `email`,
            `SEO`,
            `search engine optimization`,
            `campaigns`,
            `analytics`,
            `databases`,
            `pricing`,
          ]}
          pagePath="/"
          schema={`{
              "@context": "http://schema.org",
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Organization",
                "name": "Meteor Affinity",
              }
            }`}
        />
        <Section title="Pricing">
          <div id="pricesContainer">
            <div className="flex-container">
              <button
                onClick={handleCheck}
                onKeyDown={handleCheck}
                data-target="Social"
                className={sectionToShow === "Social" ? "active" : ""}
              >
                Social/PPC
              </button>
              <button
                onClick={handleCheck}
                onKeyDown={handleCheck}
                data-target="LandingPageServices"
                className={
                  sectionToShow === "LandingPageServices" ? "active" : ""
                }
              >
                Landing Page Services
              </button>
              <button
                onClick={handleCheck}
                onKeyDown={handleCheck}
                data-target="FCOServices"
                className={sectionToShow === "FCOServices" ? "active" : ""}
              >
                Full Campaign Optimization Services
              </button>
              <button
                onClick={handleCheck}
                onKeyDown={handleCheck}
                data-target="EmailMarketing"
                className={sectionToShow === "EmailMarketing" ? "active" : ""}
              >
                Email Marketing Design and Campaign Services
              </button>
            </div>
            {sectionToShow === "Social" && <Social />}
            {sectionToShow === "LandingPageServices" && <LandingPageServices />}
            {sectionToShow === "FCOServices" && <FCOServices />}
            {sectionToShow === "EmailMarketing" && <EmailMarketing />}
          </div>
          <StyledLink
            hasShadow
            icon={`right-arrow-white`}
            isAlternative
            to={`/sign-up`}
          >
            Get a Quote
          </StyledLink>
        </Section>
      </Layout>
    </>
  )
}
export default PricingPage
